// Our main CSS
import '../css/app.css'
import MainNavigation from './modules/site-navigation'
import Accordion from './modules/accordion'
import Search from './modules/search'
import RegionSelect from './modules/region-select'
import Geolocation from './modules/geolocation/index'
Geolocation()
MainNavigation()
Accordion()
Search()
RegionSelect()

// Make so that clearing the search input closes the searchmodal
const search_inputs = document.querySelectorAll('input[type=search]')
if (search_inputs && search_inputs !== null) {
  search_inputs.forEach((input) => {
    input.addEventListener('keydown', (e) => {
      const evt = e as KeyboardEvent
      const input = evt.target as HTMLInputElement
      if (evt.key === 'Escape') {
        if (input.value !== '') {
          evt.stopPropagation()
        } else {
          evt.stopPropagation()
          input.blur()
        }
      }
    })
  })
}

const accordion_group = document.querySelectorAll('[data-ui-accordion-group]')

if (accordion_group && accordion_group.length) {
  accordion_group.forEach((el) => {
    import('./modules/accordion-group')
      .then((module) => {
        module.default(el as HTMLElement)
      })
      .catch((err) => {
        console.log(err)
      })
  })
}

const article_sliders = document.querySelectorAll('[data-article-slider]')

if (article_sliders && article_sliders.length) {
  article_sliders.forEach((el) => {
    import('./modules/article-slider')
      .then((module) => {
        module.default(el as HTMLElement)
      })
      .catch((err) => {
        console.log(err)
      })
  })
}


const personNummerInputs = document.querySelectorAll('[name=personnummer]');

function handlePersonnummerChange(input) {
  input.setCustomValidity('');

  const target = input.dataset?.target;
  const targetElement = document.querySelector(target);

  if (!targetElement) {
    return;
  }

  const value = input.value;
  const personnummer = value.replace(/\D/g, '');
  const personnummerLength = personnummer.length;

  if (personnummerLength >= 8) {


    const year = personnummer.substr(0, 4);
    const month = personnummer.substr(4, 2);
    const day = personnummer.substr(6, 2);

    let now = new Date();
    now.setHours(0, 0, 0, 0)
    const date = new Date(`${year}-${month}-${day} 00:00:01`);
    // Calculate age depending on date
    const ageDifMs = now.getTime() - date.getTime();
    const ageYear = Math.abs(new Date(ageDifMs).getUTCFullYear() - 1970);

    // remove classes hidden and block
    targetElement.classList.remove('hidden');
    targetElement.classList.remove('block');
    targetElement.classList.add(ageYear >= 18 ? 'hidden' : 'block')

  } else {
    targetElement.classList.add('hidden');
    targetElement.classList.remove('block');
    input.setCustomValidity('Ogiltigt personnummer');
  }
}

personNummerInputs.forEach((input) => {
  input.onchange = function () {
    handlePersonnummerChange(this);
  }

  handlePersonnummerChange(input);


});
